@mixin button(
  $padding: 6px 10px,
  $font-size: 12px,
  $border-radius: 4px,
  $bg-color: #ffffff,
  $font-color: #000000
) {
  padding: $padding;
  display: inline-block;
  border: none;
  border-radius: $border-radius;
  background: $bg-color;
  font-size: $font-size;
  color: $font-color;
  cursor: pointer;
}

.MuiTableRow-root .MuiIconButton-root.Mui-disabled {
  visibility: hidden;
  display: none;
}

.MD100 .MuiTableRow-root .MuiTableCell-root:first-child {
  display: none;
  width: 0;
}

._loading_overlay_content {
  > div {
    margin: 0 auto;
  }
}

#mapContainer {
  display: flex;
  flex: 1 1 auto;
  // height: 600px;
  height: 57.5vh;
  position: relative;

  &.dark {
    .details,
    .leaflet-popup-tip,
    .leaflet-popup-content-wrapper {
      background-color: #1e1e1e;
      color: #ffffff;
    }

    .leaflet-popup-content {
      button {
        @include button;
      }
    }

    .mapboxgl-popup-content {
      background-color: #1e1e1e;
      color: #ffffff;
    }
    .mapboxgl-popup-close-button {
      background-color: #1e1e1e;
      color: #ffffff;
    }

    .custom-block {
      border-bottom: 2px solid #ffffff;
    }
    .custom-block .custom-block-layer {
      border-bottom: 2px solid #ffffff;
    }
  }

  &.light {
    .details,
    .leaflet-popup-tip,
    .leaflet-popup-content-wrapper {
      background-color: #ffffff;
      color: #000000;
    }

    .leaflet-popup-content {
      button {
        @include button(6px 10px, 12px, 4px, #5945cc, #ffffff);
      }
    }

    .mapboxgl-popup-content {
      background-color: #ffffff;
      color: #000000;
    }
    .mapboxgl-popup-close-button {
      background-color: #ffffff;
      color: #000000;
    }
  }
}

.details {
  padding: 15px;

  ul {
    text-align: left;
    padding-left: 15px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .directional {
    display: flex;

    .direction-title {
      border-bottom: solid 1px;
    }

    .direction-1 {
      margin-right: 10px;
    }
  }
}

.current {
  padding: 8px 16px;
}

.logo-wrapper {
  margin-top: auto !important;
}

.logo {
  // height: 7vh;
  //width: 5.25vw;
  height: 7vh;
  width: 40%; // padding: 0 16px;
}

.logo2 {
  // height: 6.1vh;
  // width: 9.5vw;
  // padding: 0 16px;
  // padding-right: 25px;
  // padding-right: 1.25vw;
  height: 6vh;
  width: 60%; // padding: 0 16px;
  // justify-content: center;
  // align-items: center;
}

.link-disabled {
  text-decoration: line-through !important;
}

.leaflet-container {
  width: 100%;
  height: 100%;
  z-index: 2;
}

.home-button {
  background-color: #ffffff;
  color: #000000;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin-left: 0;
  padding: 3px;
}

.table-wrap,
.table-null {
  margin-bottom: 0 !important;
}

.table-null {
  padding: 16px;
}

.custom-block {
  font-size: 1rem;
  // text-align: center;
  border-bottom: 2px solid black;
}
.custom-block .custom-block-layer {
  font-weight: 700;
  // font-size: 16px;
  // margin-bottom: -0.4rem;
  margin-bottom: 0.4rem;
  text-align: center;
  border-bottom: 2px solid black;
}
.custom-block .custom-block-title {
  font-weight: 700;
  margin-bottom: -0.4rem;
}
.custom-block .custom-block-text {
  margin-bottom: -0.4rem;
}

.from-to-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.easyPrintHolder .a3CssClass {
  /* background-image: url(data:image/svg+xml;utf8;base64,PD9...go=); */
  background-image: url("./assets/icons/crop_black_24dp.svg");
}
